<template>
    <div class="p-2 w-1/2 h-full">
        <div class="border rounded shadow p-5">
            <div class="border-b p-3 flex justify-between align-center">
                <div>
                    <p class="uppercase text-gray-600">{{ name }}</p>
                    <p class="font-extralight text-sm">Total: {{ calc_nb_data(data) }}</p>
                </div>
                <div>
                    <p class="font-extralight">{{ days }} jours</p>
                </div>
            </div>
            <div style="height:1000px">
                <canvas :id="id"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import axiosGet from '@/assets/script/axios.js'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.plugins.unregister(ChartDataLabels);

export default {
    name: 'BarChart',
    props: [
        'id',
        'type',
        'fill',
        'url',
        'backgroundColor',
        'borderColor',
        'borderWidth',
        'height',
        'width',
        'company',
        'days',
        'name',
        'horizontal',
    ],
    data: function () {
        return ({
            data: [],
            labels: [],
            chartInstance: null,
        })
    },
    methods: {
        mountChart(){
            var ctx = document.getElementById(this.id).getContext('2d');
            this.chartInstance = new Chart(ctx, {
                type: this.type ? this.type : 'bar',
                plugins: [ChartDataLabels],
                data: {
                    labels: this.labels,
                    datasets: [{
                        label: this.title,
                        data: this.data,
                        fill: false,
                        backgroundColor: this.backgroundColor,
                        borderColor: this.borderColor,
                        borderWidth: this.borderWidth ? this.borderWidth : 1,
                        // barThickness: 10,
                        // offset: true,
                    }]
                },
                options: {
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: false,
                    },
                    layout: {
                        padding: {
                            top: 25,
                            right: 5
                        }
                    },
                    scales: {
                        xAxes: [{
                            // display: false,
                            type: 'logarithmic',
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                display: false,
                            }
                        }],
                        yAxes: [{
                            display: true,
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                autoSkip: false,
                                padding: 10,
                            }
                        }]
                    },
                    plugins: {
                        datalabels: {
                            anchor: 'end',
                            align: 'right',
                            offset: 2,
                            formatter: function(value) {
                                return value || null;
                            },
                        }
                    },
                }
            });
        },
        async updateChartData () {
            await axiosGet.get(this.url, {
                params: {
                    last_days: this.days ? this.days : '',
                    company: this.company ? this.company: '',
                },
            })
            .then((response) => {
                // this.data = response.data.data
                // this.labels = response.data.labels
                this.data = []
                this.labels = []
                let data = response.data
                for (let i = 0; i < data.length; i++) {
                    if(data[i]['value'] >= 0){
                        this.data.push(data[i]['value'])
                        this.labels.push(data[i]['label'])
                    }
                }
                this.chartInstance.data.datasets.forEach((dataset) => {
                    dataset.data = this.data
                })
                this.chartInstance.data.labels = this.labels
                this.chartInstance.update()
            })
        },
        async getData () {
            await axiosGet.get(this.url, {
                params: {
                    last_days: this.days ? this.days : '',
                    company: this.company ? this.company: '',
                },
            })
            .then((response) => {
                this.data = []
                this.labels = []
                let data = response.data
                for (let i = 0; i < data.length; i++) {
                    if(data[i]['value'] >= 0){
                        this.data.push(data[i]['value'])
                        this.labels.push(data[i]['label'])
                    }
                }
                // this.data = response.data.data
                // this.labels = response.data.labels
                this.mountChart()
            })
        },
        calc_nb_data (data) {
            let nb = 0

            data.forEach(element => {
                nb += element
            })
            return nb
        },
    },
    watch: {
        days () {
            this.updateChartData()
        },
    },
    mounted () {
        this.getData()
    }
}
</script>

<style scoped>
</style>